import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
  // scrollSpy('.header-nav-sub:visible', {offset: 0})
  // smoothScroll('a[href^="#"]', {offset: 0})

  $(document).scroll(function () {
    var scroll = $(this).scrollTop()
    if ($(window).width() >= 991 && scroll > 0) {
      $('.header').addClass('fixed')
    } else {
      $('.header').removeClass('fixed')
    };
  })
})

// Init Tabs
function initTabs () {
  // Finde alle Tab-Container auf der Seite
  const tabContainers = document.querySelectorAll('.tab--wrapper')

  // Iteriere durch alle Tab-Container
  tabContainers.forEach((tabContainer) => {
    // Finde die Tab-Links und Tab-Panes innerhalb des aktuellen Tab-Container
    const tabLinks = tabContainer.querySelectorAll('.nav-link')
    const tabContents = tabContainer.querySelectorAll('.tab-pane')

    // Füge einen Klick-Event-Listener zu allen Tab-Links in diesem Tab-Container hinzu
    tabLinks.forEach((tabLink, index) => {
      tabLink.addEventListener('click', function (e) {
        e.preventDefault()

        tabLinks.forEach((link) => {
          link.classList.remove('active')
        })

        this.classList.add('active')

        tabContents.forEach((content) => {
          content.classList.remove('show', 'active')
        })

        tabContents[index].classList.add('show', 'active')
      })
    })

    const firstTabLink = tabLinks[0]
    if (firstTabLink) {
      firstTabLink.click()
    }
  })
}
initTabs()

// Konfiguration Glider
const sliderConfigurations = [
  {
    sliderClass: '.partner-slider .neos-contentcollection',
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: {
      prev: '.partner-slider .glider-prev',
      next: '.partner-slider .glider-next'
    },
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6
        }
      }
    ]
  },
  {
    sliderClass: '.content-slider .neos-contentcollection',
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: {
      prev: '.content-slider .glider-prev',
      next: '.content-slider .glider-next'
    }
  },
  {
    sliderClass: '.image-slider .neos-contentcollection',
    slidesToShow: 2.5,
    slidesToScroll: 2,
    scrollLock: true,
    arrows: {
      prev: '.image-slider .glider-prev',
      next: '.image-slider .glider-next'
    },
    responsive: [
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4.8,
          slidesToScroll: 2
        }
      }
    ]
  }
]

sliderConfigurations.forEach(config => {
  const sliderElement = document.querySelector(config.sliderClass)
  if (sliderElement) {
    // eslint-disable-next-line no-new
    new Glider(sliderElement, {
      slidesToShow: config.slidesToShow,
      slidesToScroll: config.slidesToScroll,
      exactWidth: config.exactWidth,
      scrollLock: config.scrollLock,
      arrows: {
        prev: config.arrows.prev,
        next: config.arrows.next
      },
      responsive: config.responsive
    })
  }
})

document.addEventListener('DOMContentLoaded', function () {
  // Funktion zum Abrufen des Cookies
  function getCookie (name) {
    const matches = document.cookie.match(new RegExp(
      '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
    ))
    return matches ? decodeURIComponent(matches[1]) : undefined
  }

  // Cookie `KD_GDPR_CC` abrufen
  const cookieValue = getCookie('KD_GDPR_CC')

  // Überprüfen, ob der Cookie `"facebook-pixel"` enthält
  if (cookieValue && cookieValue.includes('"facebook-pixel"')) {
    console.log('Facebook Pixel erlaubt – Skript wird eingefügt.')

    // Facebook-Pixel-Code als String
    const facebookPixelCode = `
          <!-- Meta Pixel Code -->
          <script>
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', 'xxx'); 
          fbq('track', 'PageView');
          </script>
          <noscript><img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=xxx&ev=PageView&noscript=1"
          /></noscript>
          <!-- End Meta Pixel Code -->
      `

    // Dynamisch in den `<head>`-Bereich einfügen
    const headElement = document.head || document.getElementsByTagName('head')[0]
    const scriptElement = document.createElement('div')
    scriptElement.innerHTML = facebookPixelCode
    headElement.appendChild(scriptElement)
  } else {
    console.log('Facebook Pixel nicht erlaubt.')
  }
})
