import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
  // scrollSpy('.header-nav-sub:visible', {offset: 0})
  // smoothScroll('a[href^="#"]', {offset: 0})

  $(document).scroll(function () {
    var scroll = $(this).scrollTop()
    if ($(window).width() >= 991 && scroll > 0) {
      $('.header').addClass('fixed')
    } else {
      $('.header').removeClass('fixed')
    };
  })
})

// Init Tabs
function initTabs () {
  // Finde alle Tab-Container auf der Seite
  const tabContainers = document.querySelectorAll('.tab--wrapper')

  // Iteriere durch alle Tab-Container
  tabContainers.forEach((tabContainer) => {
    // Finde die Tab-Links und Tab-Panes innerhalb des aktuellen Tab-Container
    const tabLinks = tabContainer.querySelectorAll('.nav-link')
    const tabContents = tabContainer.querySelectorAll('.tab-pane')

    // Füge einen Klick-Event-Listener zu allen Tab-Links in diesem Tab-Container hinzu
    tabLinks.forEach((tabLink, index) => {
      tabLink.addEventListener('click', function (e) {
        e.preventDefault()

        tabLinks.forEach((link) => {
          link.classList.remove('active')
        })

        this.classList.add('active')

        tabContents.forEach((content) => {
          content.classList.remove('show', 'active')
        })

        tabContents[index].classList.add('show', 'active')
      })
    })

    const firstTabLink = tabLinks[0]
    if (firstTabLink) {
      firstTabLink.click()
    }
  })
}
initTabs()

// Konfiguration Glider
const sliderConfigurations = [
  {
    sliderClass: '.partner-slider .neos-contentcollection',
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: {
      prev: '.partner-slider .glider-prev',
      next: '.partner-slider .glider-next'
    },
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6
        }
      }
    ]
  },
  {
    sliderClass: '.content-slider .neos-contentcollection',
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: {
      prev: '.content-slider .glider-prev',
      next: '.content-slider .glider-next'
    }
  },
  {
    sliderClass: '.image-slider .neos-contentcollection',
    slidesToShow: 2.5,
    slidesToScroll: 2,
    scrollLock: true,
    arrows: {
      prev: '.image-slider .glider-prev',
      next: '.image-slider .glider-next'
    },
    responsive: [
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4.8,
          slidesToScroll: 2
        }
      }
    ]
  }
]

sliderConfigurations.forEach(config => {
  const sliderElement = document.querySelector(config.sliderClass)
  if (sliderElement) {
    // eslint-disable-next-line no-new
    new Glider(sliderElement, {
      slidesToShow: config.slidesToShow,
      slidesToScroll: config.slidesToScroll,
      exactWidth: config.exactWidth,
      scrollLock: config.scrollLock,
      arrows: {
        prev: config.arrows.prev,
        next: config.arrows.next
      },
      responsive: config.responsive
    })
  }
})
